import TableItems from "components/Table/Table";
import Card from "components/card/Card";
import {
  useGetDashboardItemsQuery,
  DashboardItemsResponseDto,
  useSetStatusFlagOfUserMutation,
} from "./dashboardService";
import FundedState from "components/funded-state/FundedState";
import LoanProgessBar from "components/ui/loan-progress-bar/LoanProgessBar";
import { useSelector, useDispatch } from "react-redux";
import DashboardPopUp from "pages/dashboard-popup/Dashboard-popup";
import "./dashboard.scss";
import {
  getCurrentUserName,
  getCurrentUserId,
  setLogout,
} from "features/authSlice";
import { useNavigate } from "react-router";
import { showDocuments } from "utilities/UserversionFeatures";
import { useState } from "react";
import NafInsuranceAd from "../advertisements/NafInsurance";
import { useGetFeatureFlagsQuery } from "services/common/featureFlagService";
import { DefaultConditionsCount } from "utilities/Constant";

export const tooltipIndexValues = {
  initial: 0,
  conditions: 1,
  milestones: 2,
  completed: 3,
};

export default function Dashboard() {
  const userName = useSelector(getCurrentUserName);
  const userId = useSelector(getCurrentUserId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, isError, error } = useGetDashboardItemsQuery(userId);
  const [tooltipIndex, setTooltipIndex] = useState(tooltipIndexValues.initial);

  let component: JSX.Element | null = null;
  if (
    isError &&
    typeof error === "object" &&
    "status" in error &&
    error.status === 401
  ) {
    dispatch(setLogout());
    navigate("/", { replace: true, state: { path: location.pathname } });
  }
  if (data == undefined) {
    return null;
  }
  const {
    loanStatus: { status, subStatus },
    closingDate,
  }: DashboardItemsResponseDto = data;

  switch (status) {
    case "Processing":
    case "Underwriting":
    case "Approval":
    case "FinalReview":
    case "Close":
    default:
      component = (
        <OutStandingItemsCard
          tooltipIndex={tooltipIndex}
          setTooltipIndex={setTooltipIndex}
          isStatusInStarted={status == "Started"}
        />
      );
      break;
    case "Funded":
      component = <FundedState userName={userName.toUpperCase() + "!"} />;
      break;
  }

  return (
    <>
      {status != "Funded" && (
        <LoanProgessBar
          status={status}
          currentStatus={subStatus}
          closingDate={closingDate}
          showTooltip={tooltipIndex === tooltipIndexValues.milestones}
          setTooltipIndex={setTooltipIndex}
        />
      )}
      {component}
    </>
  );
}

const OutStandingItemsCard = (props: {
  tooltipIndex: number;
  setTooltipIndex: (index: number) => void;
  isStatusInStarted: boolean;
}) => {
  const { tooltipIndex, setTooltipIndex, isStatusInStarted } = props;
  const userId = useSelector(getCurrentUserId);
  const userName = useSelector(getCurrentUserName);
  const { pendingItems, isUserNew, isGetItemError, getItemError } =
    useGetDashboardItemsQuery(userId, {
      selectFromResult: ({ data, isError, error }) => ({
        pendingItems: data!.pendingItems,
        isUserNew: data!.isNew,
        isGetItemError: isError,
        getItemError: error,
      }),
    });
  const [updateUserstatus, { isError, error }] =
    useSetStatusFlagOfUserMutation();
  const { data: featureFlag } = useGetFeatureFlagsQuery();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (isGetItemError || isError) {
    if (
      typeof getItemError === "object" &&
      "status" in getItemError &&
      getItemError.status === 401
    ) {
      dispatch(setLogout());
      navigate("/", { replace: true, state: { path: location.pathname } });
    }
    if (
      typeof error === "object" &&
      "status" in error &&
      error.status === 401
    ) {
      dispatch(setLogout());
      navigate("/", { replace: true, state: { path: location.pathname } });
    }
    // should add code for other errors
  }
  const handleClose = () => {
    void updateUserstatus(userId);
    setTooltipIndex(tooltipIndexValues.conditions);
  };
  const { data } = useGetDashboardItemsQuery(userId);
  const loanstatus = data?.loanStatus.status != "Started";
  const getConditionsTile = () => {
    return (
      <>
        <TableItems
          Tablevalues={pendingItems}
          showTooltip={tooltipIndex === tooltipIndexValues.conditions}
          setTooltipIndex={setTooltipIndex}
          isStatusInStarted = {isStatusInStarted}
        />
      {featureFlag?.insuranceAdEnabled && <NafInsuranceAd />}
        
      </>
    );
  };

  return (
    <>
      <div className={(loanstatus || pendingItems.length == DefaultConditionsCount) ? "generic-condition" : "conditionsContainer"}>
        <Card
          header={
            tooltipIndex === tooltipIndexValues.conditions
              ? ""
              : "Tasks to be completed"
          }
          body={getConditionsTile()}
        />
      </div>
      {showDocuments() && (
        <div className="dashboard-content">
          <p className="dashboard-content__text">
            * All documents uploaded by you are visible under the My Documents
            tab
          </p>
        </div>
      )}
      {isUserNew && (
        <DashboardPopUp
          onClose={() => {
            handleClose();
          }}
          userName={userName.split(" ")[0]}
        />
      )}
    </>
  );
};
