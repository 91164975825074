import { useRoutes } from "react-router-dom";
import Application from "pages/application/Application";
import Authentication from "pages/authentication/Authentication";
import ForgotPassword from "pages/forgot-password/ForgotPassword";
import Login from "pages/login/Login";
import Registration from "pages/registration/Registration";
import RegistrationCoBorrower from "pages/registration/RegistrationCoBorrower";
import ThankYou from "pages/thank-you/ThankYou";
import Dashboard from "pages/dashboard/home/Dashboard";
import Documents from "pages/dashboard/documents/Documents";
import SharedDashboard from "components/shared-dashboard/SharedDashboard";
import AccountChek from "pages/asset-verification/AccountChek";
import Header from "components/ui/header/Header";
import RequireAuth from "features/RequireAuth";
import PublicRoute from "features/PublicRoute";
import Version from "pages/version/Version";
import Welcome from "pages/welcome/Welcome";
import RecoverPassword from "pages/recover-password/RecoverPassword";
import Mfa from "pages/MFA/MFA";
import WelcomeLos from "pages/welcome/WelcomeLos";
import RegistrationAuth from "pages/authentication/RegistrationAuth";
import AuthRouting from "components/auth-routing/AuthRouting";
import RegisterExternalUser from "pages/registration/external-user/RegisterExternalUser";
import MyLoans from "pages/multi-app/MyLoans";

export type Route =
  | "/"
  | "/authcode"
  | "/registrationAuth"
  | "/mfa"
  | "/registration"
  | "/registrationCoBorrower"
  | "/forgotpassword"
  | "/resetpassword"
  | "/thankyou"
  | "/path"
  | "/application"
  | "/apitests"
  | "/accountchek"
  | "/dashboard"
  | "/versions"
  | "/applicationcomplete"
  | "/los"
  | "/los-auth"
  | "/my-loans";

export function Routes() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Header />,
      children: [
        {
          element: <PublicRoute />,
          children: [
            { index: true, element: <Login /> },
            {
              path: "registration",
              element: <Registration />,
            },
            {
              path: "registrationCoBorrower",
              element: <RegistrationCoBorrower />,
            },
            {
              path: "registrationExternalUser",
              element: <RegisterExternalUser />,
            },
            {
              path: "forgotpassword",
              element: <ForgotPassword />,
            },
            {
              path: "mfa",
              element: <Mfa />,
            },
            {
              path: "authcode",
              element: <Authentication />,
            },
            {
              path: "registrationAuth",
              element: <RegistrationAuth />,
            },
            { path: "resetpassword", element: <RecoverPassword /> },
            { path: "los", element: <WelcomeLos /> },
          ],
        },
        {
          element: <RequireAuth />,
          children: [
            { path: "post-auth", element: <AuthRouting /> },
            { path: "path", element: <Welcome /> },
            { path: "los-auth", element: <WelcomeLos /> },
            {
              path: "my-loans",
              element: <MyLoans />,
            },
            { path: "application", element: <Application /> },
            { path: "accountchek", element: <AccountChek /> },
            {
              path: "dashboard",
              element: <SharedDashboard />,
              children: [
                { index: true, element: <Dashboard /> },
                {
                  path: "documents",
                  element: <Documents />,
                },
              ],
            },
            { path: "applicationcomplete", element: <ThankYou /> },
          ],
        },
        { path: "versions", element: <Version /> },
      ],
    },
  ]);
  return routes;
}
