import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDesktopChecker from "hooks/useDesktopChecker";
import { NavLink } from "react-router-dom";
import { showDocuments } from "utilities/UserversionFeatures";

export interface INavBarProps {
  userName?: string;
  showNavLinks?: boolean;
  tooltip?: boolean;
  showMyLoans?: boolean;
}

export function NavLinks({ className = "" }: Readonly<{ className?: string }>) {
  const navbarClass = "navbar__links" + " " + className;
  const isDesktop = useDesktopChecker();
  return (
    <>
      {isDesktop ? (
        <div className={navbarClass}>
          <NavLink to="/dashboard" className="link" end>
            <p>My Dashboard</p>
          </NavLink>
          {showDocuments() && (
            <NavLink to="/dashboard/documents" className="link">
              My Documents
            </NavLink>
          )}
        </div>
      ) : (
        <div className={navbarClass}>
          <NavLink to="/dashboard" className="link" end>
            <FontAwesomeIcon
              className="link__icon"
              icon={regular("table-columns")}
            />
            <p className="link__text">My Dashboard</p>
          </NavLink>
          {showDocuments() && (
            <NavLink to="/dashboard/documents" className="link">
              <FontAwesomeIcon
                className="link__icon"
                icon={regular("file-lines")}
              />
              <p className="link__text">My Documents</p>
            </NavLink>
          )}

          <div className="lines"></div>
        </div>
      )}
    </>
  );
}
