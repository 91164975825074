import LoanCard from "components/loan-card/LoanCard";
import { useGetMyLoansQuery } from "pages/dashboard/home/dashboardService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./my-loans.scss";
import Footer from "components/footer/Footer";
export default function MyLoans() {
  const { data } = useGetMyLoansQuery();

  return (
    <div className="loans-section">
      <div className="loans-section__wrapper">
        <div className="loans-section__header">
          <div className="loans-section__header-icon-bg">
            <FontAwesomeIcon
              icon={solid("file-pen")}
              className="loans-section__header-icon"
            />
          </div>
          <span className="loans-section__header-title">My Loans</span>
        </div>
        <div className="loans-section__card-container">
          {data?.map((item, index) => (
            <div className="loans-section__card" key={index}>
              <LoanCard
                property={item.property}
                loanPurpose={item.loanPurpose}
                loanAmount={item.loanAmount}
                status={item.status}
                startedDate={item.startedDate}
                documentStatus={item.documentStatus}
              />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
