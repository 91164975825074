import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { getStepData } from "components/question/questionSlice";
import "./nav-bar.scss";
import Logout from "../logout/Logout";
import { useSelector } from "react-redux";
import useDesktopChecker from "hooks/useDesktopChecker";
import { showDocuments } from "utilities/UserversionFeatures";
import NavbarDropDown from "./NavbarDropDown";
import { INavBarProps, NavLinks } from "./NavBarUtilities";
import { useGetFeatureFlagsQuery } from "services/common/featureFlagService";

export default function NavBar({
  userName,
  showNavLinks = false,
}: INavBarProps) {
  const navRef = useRef<HTMLElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const showNavbar = () => {
    setShowMenu(!showMenu);
  };
  const isDesktop = useDesktopChecker();
  const stepData = useSelector(getStepData);
  const isSummary = stepData?.stepType === "summary";
  const { data: featureFlag } = useGetFeatureFlagsQuery();
  return (
    !featureFlag?.newHeaderEnabled ?
      <div className="navbar-container">
        {
          isDesktop ? (
            <nav ref={navRef} className="navbar">
              {!isSummary && showNavLinks && <NavLinks />}
              {userName && <Logout name={userName} />}
              <span onClick={showNavbar} className="navbar__btn navbar__close-btn">
                <FontAwesomeIcon icon={regular("xmark")} />
              </span>
            </nav>
          ) : (
            <>
              {showMenu && (
                <nav ref={navRef} className="navbar">
                  {!isSummary && showNavLinks && <NavLinks />}
                  {userName && <Logout name={userName} />}
                  <span
                    onClick={showNavbar}
                    className="navbar__btn navbar__close-btn"
                  >
                    <FontAwesomeIcon icon={regular("xmark")} />
                  </span>
                </nav>
              )}
            </>
          )
        }

        {
          isDesktop || showNavLinks ? (
            <span onClick={showNavbar} className="navbar__btn">
              <FontAwesomeIcon icon={regular("bars")} />
            </span>
          ) : (
            <Logout />
          )
        }
      </div >
      :
      <NavbarDropDown userName={userName} showNavLinks={showNavLinks} showMyLoans={featureFlag?.multiAppEnabled} />
  );
}